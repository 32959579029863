.our-story-about-us{
    padding: 150px 0px;
}
.our-story-about-us-title{
    font-size: 30px;
    margin-top: 60px;
}
.our-story-about-us-img{
    float: right;
    max-height: 500px;
    width: 100%;
}
@media(max-width:1024px){
    .our-story-about-us {
        padding: 50px 0px;
    }
}
@media(max-width:768px){
    .our-story-about-us-title{
        font-size: 24px;
    }
}
@media(max-width:500px){
    .our-story-about-us-para{
        font-size: 15px;
    }
}