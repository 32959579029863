.banner-image {
  height: 100vh;
  width: 100%;
}
.banner-text {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 2px solid red; */
  width: 100%;
  height: 100%;
  align-items: center;
  color: white;
}
.banner-text h2 {
  font-size: 175px;
  font-style: italic !important;
}
.banner-title-para {
  font-size: 40px;
  font-weight: 400;
}
.banner-title-para h1{
  font-size: 40px;
}
.banner-btn {
  border: none;
  background-color: var(--primeColor02);
  border-radius: 25px;
  padding: 5px 23px;
  margin-top: 19px;
}
.banner-title-para {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 13px 0px;
}
.banner-title-para::after {
  position: absolute;
  content: "";
  height: 4px;
  background-color: var(--primeColor01);
  width: 83px;
  /* top: 0; */
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-prev,
.swiper-button-next {
  border: 1px solid var(--primeColor02) !important;
  padding: 1px 5px !important;
  color: var(--primeColor01) !important;
  width: 65px !important;
  height: 65px !important;
}

@media (max-width: 1024px) {
  .banner-text h2 {
    font-size: 129px;
  }
}

@media (max-width: 768px) {
  .banner-title-para {
    font-size: 34px;
  }
  .banner-title-para h1{
    font-size: 34px;
  }
  .banner-text h2 {
    font-size: 110px;
  }
}
@media (max-width: 500px) {
  .banner-title-para {
    font-size: 22px;
  }
  .banner-title-para h1{
    font-size: 22px;
  }
  .banner-text h2 {
    font-size: 70px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    border: 1px solid var(--primeColor02) !important;
    padding: 1px 5px !important;
    color: var(--primeColor01) !important;
    width: 48px !important;
    height: 48px !important;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 29px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
  }
  .banner-image {
    height: 65vh;
    width: 100%;
}
.banner-btn {
    padding: 5px 16px;
    font-size: 14px;
}
}
