.home-about-us-sec{
    height: 100vh;
    width: 100%;
    background-image: url('../Assert/images/homeAboutUsBanner.avif');
    background-attachment: fixed;
    position: relative;
}
.home-about-us-div{
    height: 100vh;
    width: 45vh;
    background-color: #fff;
    height: 100vh;
    width: 70vh;
    background-color: #fff;
    position: absolute;
    right: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media(max-width:768px){
    .home-about-us-div{
        right: unset;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media(max-width:500px){
    .home-about-us-div{
        width: 50vh;
    }
    .home-about-us-title {
        font-size: 30px !important;
    }
    .home-about-us-para{
        font-size: 16px !important;

    }
}

/* .home-about-us-title{
    position: relative;
}
.home-about-us-discover h4::before{
    position: absolute;
    content: "";
    height: 5px;
    width: 3px;
    background-color: red;
    left: 0;
} */
/* .home-about-us-discover::before{
    position: absolute;
    content: "";
    height: 5px;
    width: 3px;
} */
/* home-about-us-discover */
.line-home-about-us{
    display: flex;
    position: relative;
}
.line-home-about-us div::after {
    position: absolute;
    content: "";
    background-color: var(--primeColor01);
    left: 0;
    width: 45px;
    bottom: 40%;
    height: 2px;
}
.home-about-us-discover-outter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
}
.line-home-about-us {
    display: flex;
    gap: 60px;
    position: relative;
    /* justify-content: center; */
    align-items: center;
}
.read-full-story-main{
    position: relative;
    display: flex;
}
.read-full-story-main span::after{
    position: absolute;
    content: "";
    background-color: var(--primeColor01);
    left: 0;
}


.line-home-about-us-read{
    display: flex;
    position: relative;
}
.line-home-about-us-read div::after {
    position: absolute;
    content: "";
    background-color: var(--primeColor01);
    right: 0;
    bottom: 0;
    width: 45px;
    /* bottom: 10px; */
    height: 2px;
}
.line-home-about-us-read {
    display: flex;
    gap: 60px;
    position: relative;
    /* justify-content: center; */
    align-items: center;
}
.home-about-us-discover-outter-read{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home-about-us-read{
    color: var(--primeColor01);
    cursor: pointer;
}
.home-about-us-para{
    margin-bottom: 45px;
}
.home-about-us-title{
    font-size: 47px;
}