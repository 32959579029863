
.home-gallery-section{
    /* height: 100vh; */
    width: 100%;
    padding: 75px;
    background-color: rgba(255, 255, 0, 0.3);
}
.line-home-gallery{
    display: flex;
    position: relative;
}
.line-home-gallery div::after {
    position: absolute;
    content: "";
    background-color: var(--primeColor01);
    left: 0;
    width: 45px;
    bottom: 40%;
    height: 2px;
}
.line-home-gallery {
    display: flex;
    gap: 60px;
    position: relative;
    /* justify-content: center; */
    align-items: center;
}