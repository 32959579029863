.header-logo{
    height: 9vh;
    transform: scale(1.2);
}

.collapse-two{
    justify-content: end;
}
.header-main-container{
    font-size: 25xp;
}
.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl{
    font-weight: 900;
}