.services-right-main{
    position: relative;
}
.services-title{
    font-size: 55px;
    padding-top: 35px;
    
    
}
.services-right-title::before{
    position: absolute;
    content: "";
    background-color: var(--primeColor01);
    height: 2px;
    width: 42px;
    bottom: -5px;

}
.services-right-title{
    font-size: 30px;
    padding-top: 78px;
    
}
.service-para{
    font-size: 18px;
    color: gray;
    
}
.service-img{
    width: 100%;
    height: 100%;
}

/* accordion start */
.accordion-button:not(.collapsed) {
    background-color: transparent;
  }
  
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: transparent !important;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
  }
  .accordion {
    --bs-accordion-border-color: #fff !important;
    --bs-accordion-active-bg: #fff !important;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgb(255, 255, 255);
  }
  .accordion-button:focus {
    box-shadow: #fff;
  }
  
  .service-para {
    color: #1b1b1b;
    font-weight: 300;
    font-family: var(---font01);
    /* font-weight: 300; */
  }
  .service-section-1-overlay-section {
    /* border: 1px solid blue; */
    transform: translate(0px, -180px);
  }
  .service-section-svg-overlay-btn-con {
    display: inline-block;
  }
  .service-section-overlay-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    padding: 8px;
    border-radius: 50px;
    background-color: #363636;
  }
  
  .service-section-overlay-btn svg {
    font-size: 20px;
    color: white;
    transition: all 0.4s;
  }
  
  .service-section-overlay-btn:hover svg {
    transform: rotate(-90deg);
  }
  .service-grid-left {
    padding: 84px 80px 94px 0;
  }
  /* .accordion-item{
      border-bottom: 1px solid #e4e4e4;
      border-top: 1px solid #e4e4e4;
    } */
  .accordion-title {
    text-transform: capitalize;
  }
  .accordion-button::after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC") !important;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIUlEQVR4nGNgGAWjYBSMApLBfyrhgbNgFIyCUTAKGDABAOkcI91xODUvAAAAAElFTkSuQmCC") !important;
  
    /* transform: rotate(90deg); */
  }
  .accordion-button {
    padding: unset;
  }
  .accordion-body {
    padding: unset;
  }
  .input-field-from-to {
    margin-left: 9px;
  }
  .filter-apply-btn {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    border-radius: 0.375rem;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
    padding: 0.625rem 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .accordion-header {
    padding: 0px;
  }
  .accordion-para{
    color: gray;
    font-style: italic;
  }
/* accordion end */

ul li::marker {
    color: var(--primeColor02); /* Change the bullet color to red */
}
ul a li{
    color: black;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 4px;
}

.list-active{
    color: var(--primeColor02) !important;
}