.welcome-about-us-sec{
    padding: 200px 0px;
    /* height: 100vh; */
    width: 100%;
    background-image: url('../Assert/about_bg.avif');
    background-position: bottom;
    background-repeat: no-repeat;
}
.welcome-about-us-title{
    font-size: 30px;
}

@media(max-width:768px){
    .welcome-about-us-sec{
        padding: 85px 0px;
    }
}
@media(max-width:500px){
    .welcome-about-us-img{
        margin-bottom: 30px;
    }
}