.make-a-request-sec{
    height: 45vh;
    width: 100%;
    background: linear-gradient(to bottom , rgb(0,0,0,0.3),rgb(0,0,0,0.3)) ,url('../Assert/upcommingEvents.avif');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
}
.make-a-req-main{
    display: flex;
    position: absolute;
    left: 0;
    /* border: 2px solid red; */
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    color: white;
}
.make-a-req-title{
    font-size: 50px;
}
@media(max-width:768px){
    .make-a-req-title {
        font-size: 40px;
    }
}
@media(max-width:500px){
    .make-a-req-title {
        font-size: 25px;
    }
}