@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
body, html{
  font-family: "Playfair Display", serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  }
body, html{
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;

}

:root{
  --primeColor01: #2A327F;
  --primeColor02: #FFFF00;
  --primeColor03:  #808080;
}