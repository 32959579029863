.sec-contact {
    padding: 50px 0px;
  }
  
  .contactus-section-title {
    font-size: var(---fontSize01);
    font-weight: 700;
    line-height: 1em;
    color: var(--color1);
    position: relative;
    /* margin-bottom: 10px; */
    padding-bottom: 9px;
  }
  .contactus-section-title::after {
    content: "";
    background-color: #02010114;
    position: absolute;
    z-index: 1000;
    width: 80%;
    border-radius: 5px;
    height: 1px;
    left: 10px;
    bottom: 0px;
  }
  .contact-icon {
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding-top: 5px;
  }
  .call-icon {
    font-size: 30px;
    color: var(--prime01);
  }
  .social-meida-icon {
    display: flex;
    gap: 35px;
  }
  .social-meida-icon div svg {
    font-size: 15px;
  }
  .sec-contact {
    padding: 50px 0px;
  }


  /*  */
  .well {
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 0.5rem;
  }
  
  /* h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 0 0.5em;
  }
  
  h2 {
    font-size: 1rem;
    font-weight: 200;
    margin: 0 0 0.5em;
    color: var(--gray-tint-20);
  } */
  
  /* hggroup{
    text-align: center;
    margin-bottom: 4rem;
  }
  
  a,p {
    font-size: 0.8125rem;
  } */
  
  form {
    width: 100%;
  }
  
  form > div {
    position: relative;
    margin-bottom: 2rem;
  }
  
  .label {
    color: var(--gray-tint-40);
    position: absolute;
    left: 0;
    top: 0.25rem;
    transition: all 0.3s;
    cursor: pointer;
  }
  textarea::placeholder {
    color: gray !important;
  }
  input,
  textarea {
    border: none;
    border-bottom: 1px solid var(--primeColor01);
    /* height: 2.25rem; */
    height: 100%;
    width: 100%;
    outline: none;
    transition: border-color 0.3s ease;
    color: gray;
  }
  
  input:focus {
    border-color: var(--gray-base);
  }
  
  input:focus + .label,
  input:valid + .label {
    transform: translateY(-1.25rem);
    font-size: 0.8125rem;
    color: var(--gray-base);
  }
  
  a {
    /* color: var(--accent-base); */
    transition: color 0.3s ease;
  }
  a:hover {
    color: var(--gray-base);
  }
  
  #forgot-passwd {
    display: block;
    margin-bottom: 4rem;
  }
  
  .button {
    width: 100%;
    /* background-color: var(--color1); */
    background-color: var(--primeColor01);
    appearence: none;
    border: none;
    padding: 3px 33px;
  
    color: white;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 100px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .button:hover {
    background-color: var(--gray-tint-10);
  }
  .button:active {
    transform: translate3d(3px, 3px, 0);
  }
  
  .button-loader {
    display: flex;
    gap: 0.25rem;
  }
  .button-loader > div {
    width: 0.8rem;
    height: 0.8rem;
    background-color: white;
    border-radius: 50%;
    animation: 1.2s infinite ease-in-out scaleUp;
  }
  
  .button-loader div:nth-child(1) {
    animation-delay: -0.32s;
  }
  .button-loader div:nth-child(2) {
    animation-delay: -0.16s;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
  }
  .button-loader {
    display: none;
  }
  
  .button.loading .button-text {
    display: none;
  }
  .button.loading .button-loader {
    display: flex;
  }
  
  .button.success {
    background-color: var(--success-base);
  }
  .button.loading {
    cursor: wait;
  }
  
  @keyframes scaleUp {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  .main-btn {
    display: flex;
    justify-content: end;
  }
  .benefit-list {
    font-family: var(---font01);
    font-weight: 300;
    font-size: 15px;
    line-height: 1.67em;
    color: var(---primeColor01);
  }
  
  .address-icon {
    margin-right: 10px;
    font-size: 31px;
  }
  .address {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  .address-detaiils {
    font-family: var(---font01);
    font-weight: 300;
    font-size: 17px;
    line-height: 1.67em;
  }
  
  /* col-6 right end */
  .social-app-icon {
    color: var(---primeColor01);
  }
  /*  */