.why-choose-us-sec{
    /* height: 100vh; */
    background-image: url('../Assert/images/Home-why-choose-us.avif');
    width: 100%;
    background-repeat: no-repeat;
    padding: 150px 0px 0px 0px;
}
.home-why-choose-us{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 45px;
}
.line-why-choose-us{
    display: flex;
    position: relative;
}
.line-why-choose-us div::after {
    position: absolute;
    content: "";
    background-color: var(--primeColor01);
    left: 0;
    width: 45px;
    bottom: 40%;
    height: 2px;
}
.line-why-choose-us {
    display: flex;
    gap: 60px;
    position: relative;
    /* justify-content: center; */
    align-items: center;
}
.home-why-choose-us-title{
    font-size: 47px;
}
.why-choose-us-flex{
    gap: 19px;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
}
.why-choose-us-icon{
    font-size: 50px;
}
.why-choose-us-icon-title{
    font-size: 28px;
}
.why-choose-us-icon-para{
    font-size: 20px;
}
@media(max-width:1024px){
    .why-choose-us-icon-para {
        font-size: 18px;
    }
}
@media(max-width:768px){
    .why-choose-us-sec {
        padding: 120px 20px 0px 20px !important;
        height: unset !important;
    }
    .why-choose-us-icon-title {
        font-size: 22px;
    }
    .why-choose-us-icon-para {
        font-size: 16px;
    }
}
@media(max-width:500px){
    .home-why-choose-us-title {
        font-size: 30px !important;
    }
}