.welcome-img {
  width: 100%;
  max-height: 400px;
}
.welcome-title {
  /* padding: 60px 243px; */
  text-align: center;
  padding: 33px 0px;
  font-size: 18px !important;
}

.welcome-div-start {
  overflow: hidden;
  position: relative;
}
.welcome-img-tilte {
  position: absolute;
  top: 0;
  /* border: 2px solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  font-size: 50px;
  color: white;
}

.welcome-div-start:hover .welcome-img-tilte {
  opacity: 1;
  transition: all 0.5s ease-out;
  background-color: rgb(27, 27, 27, 0.4);
  /* opacity: 1; */
}
.welcome-div-start:hover .welcome-img {
  transition: all 0.5s ease-out;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* .welcome-img-tilte:hover {
  opacity: 1;
} */
@media (max-width: 1024px) {
  .welcome-title {
    padding: 10px 0px;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .welcome-title {
    padding: 10px 0px;
    text-align: center;
  }
  .welcome-img-tilte {
    font-size: 30px;
  }
}
@media (max-width: 500px) {
  .welcome-img-tilte {
    font-size: 30px;
  }
  .welcome-title {
    font-size: 20px;
  }
}
