.breadcrumbs-sec{
    height: 40vh;
    position: relative;
    /* background: linear-gradient(to bottom, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3)), url(https://demo2.wpopal.com/grenda2/wp-content/uploads/2022/08/global_bc.jpg); */
    color: white;
}
.breadcrumbs-main-div{
    position: absolute;
    left: 0;
    /* border: 2px solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.breadcrumbs-main-div h1{
    /* font-size: 45px; */
    text-align: center;
}
.breadcrumbs-subtitle label{
    margin-right: 10px;
}